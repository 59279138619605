  import {
    TechRadarApi,
    TechRadarLoaderResponse,
  } from '@backstage/plugin-tech-radar';
  
  export class AwareTechRadar implements TechRadarApi {
    remoteFilePath: string;
    constructor(options: {
      remoteFilePath: string
    }){
      this.remoteFilePath = options.remoteFilePath;
    }

    async load(id: string | undefined): Promise<TechRadarLoaderResponse> {
      const fileToRead = "/aware-backstage/techradar/" + (id === undefined ? 'radar.json' : id + '.json');
      const data = await fetch(this.remoteFilePath + fileToRead).then(res => res.json());
      return data;
    }
  }