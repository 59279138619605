import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  createApiRef,
  discoveryApiRef,
  errorApiRef,
  fetchApiRef,
  identityApiRef,
  storageApiRef,
  ProfileInfoApi,
  BackstageIdentityApi,
  SessionApi,
  ApiRef,
} from '@backstage/core-plugin-api';
import {
  SamlAuth,
} from '@backstage/core-app-api';
import { AwareTechRadar } from './lib/AwareTechRadar';
import { techRadarApiRef } from '@backstage/plugin-tech-radar';
export const awareAuthApiRef: ApiRef<
    ProfileInfoApi &
    BackstageIdentityApi &
    SessionApi
> = createApiRef({
  id: 'internal.auth.aware',
});
import { ApiEntity } from '@backstage/catalog-model';
import {
  apiDocsConfigRef,
  defaultDefinitionWidgets,
} from '@backstage/plugin-api-docs';
import { grpcDocsApiWidget } from '@backstage/plugin-api-docs-module-protoc-gen-doc';
import { UserSettingsStorage } from '@backstage/plugin-user-settings';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: apiDocsConfigRef,
    deps: {},
    factory: () => {
      // load the default widgets
      const definitionWidgets = defaultDefinitionWidgets();
      // add the grpc-docs api widget to the definition widgets
      definitionWidgets.push(grpcDocsApiWidget);
      return {
        getApiDefinitionWidget: (apiEntity: ApiEntity) => {
          // find the widget for the type of api entity
          return definitionWidgets.find(d => d.type === apiEntity.spec.type);
        },
      };
    },
  }),
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory({
    api: awareAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
  //    oauthRequestApi: oauthRequestApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, configApi }) =>
    SamlAuth.create({
      discoveryApi,
      environment: configApi.getOptionalString('auth.environment'),
    }),
  }),
  createApiFactory({
    api: techRadarApiRef,
    deps: {
      configApi: configApiRef,
    },
    factory: ({configApi}) => {
      const rootPath = configApi.getOptionalString("techradar.remotePath");
      const baseUrl = configApi.getString('app.baseUrl');
      return new AwareTechRadar({
        remoteFilePath: rootPath == undefined ? baseUrl : rootPath,
      });
    }
  }),
  createApiFactory({
    api: storageApiRef,
    deps: {
     discoveryApi: discoveryApiRef,
     errorApi: errorApiRef,
     fetchApi: fetchApiRef,
     identityApi: identityApiRef
   },
   factory: deps => UserSettingsStorage.create(deps),
  }),
];